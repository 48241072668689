import { FormEvent } from "react"

export default function Language() {
  const languageChanged = (e: FormEvent<HTMLSelectElement>) => {
    console.log('Changed language to', e.currentTarget.value);
  }

  return (
    <div className="h-full text-base space mt-4 w-64">
      <select onChange={languageChanged} className="block px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-slate-200 text-sm bg-slate-600 w-full">
        <option>English</option>
        <option>Deutsch</option>
      </select>
    </div>
  )
}