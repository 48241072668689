import './App.css';
import ClosingSection from './components/ClosingSection/ClosingSection';
import FeaturesSection from './components/FeaturesSection/FeaturesSection';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import LogoBig from './components/Logo/LogoBig';
import ScopeSection from './components/ScopeSection/ScopeSection';

function App() {
  return (
    <>
      <Header />
      <LogoBig />
      <ScopeSection />
      <FeaturesSection />
      <div className="px-12 mb-2">
        <ClosingSection />
      </div>
      <Footer />
    </>
  );
}

export default App;
