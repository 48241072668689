import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  return (
    <footer id="legal" className="flex w-full h-20 bg-slate-800 text-slate-300 px-8">
      <div className="w-1/2 h-full object-none object-bottom">
        <span className="">© {new Date().getFullYear()} Dami Cloud</span>
      </div>
      <div className="inline-block ">
        <div className="relative right flex space-x-16">
          <FontAwesomeIcon className="fa-2x" icon={faFacebook} />
          <FontAwesomeIcon className="fa-2x" icon={faTwitter} />
          <FontAwesomeIcon className="fa-2x" icon={faInstagram} />
          <FontAwesomeIcon className="fa-2x" icon={faLinkedin} />
        </div>
      </div>
    </footer>
  )
}