export default function ClosingSection() {
  return (
    <section className="border-2 border-dotted">
      <div className="items-center max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          <span className="block">Ready to improve your privacy?</span>
          <span className="block text-indigo-700"> State your interest now to get updates.</span>
        </h2>
        <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
          <div className="inline-flex rounded-md shadow">
          <a href="#scope" className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-700 hover:bg-indigo-500">Interested !</a>
          </div>
        </div>
      </div>
    </section>
  )
}