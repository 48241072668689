export default function Subscription() {
  const submitForm = () => {
    console.log("I am interested")
  }
  return (
    <div className="bg-white mx-auto p-8 rounded-xl drop-shadow-2xl max-w-sm">
      <p className="text-center text-2xl mb-3 text-slate-700">
        State your interest
      </p>
      <div className="divide-y-2 divide-slate-400">
        <form>
          <input className="bg-slate-100 appearance-none border-2 border-slate-400 rounded w-full py-2 px-4 text-slate-500 leading-tight focus:outline-none focus:bg-white focus:border-indigo-600" type="text" placeholder="Name / alias" />
          <input className="bg-slate-100 appearance-none border-2 border-slate-400 rounded w-full py-2 px-4 text-slate-500 leading-tight focus:outline-none focus:bg-white focus:border-indigo-600" type="text" placeholder="Email only if you want to get notified" />
          <button 
            className="h-10 my-2 p-1 bg-indigo-700 text-white rounded-md w-full"
            onClick={submitForm}
          >
            I am interested !
          </button>
        </form>
        
        <div className="text-left text-slate-500 text-sm text-black">
          <p>
            Before stating your interest, check our <span className="font-bold">Terms, Data Policy</span> and <span className="font-bold">Cookies Policy</span>.
          </p>
        </div>
      </div>
    </div>
  )
}