import Language from "../Language/Language";
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";
import { useState } from 'react';

export default function Header() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleSidebar = () => {
    console.log(sidebarOpen);
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <header className="z-40 sticky top-0 flex  justify-between w-full h-20 bg-slate-800 text-slate-300 px-8">
      <div className="lg:hidden">
        <Logo /> {/* Logo should appear always */}

        <button onClick={toggleSidebar} className="flex items-center px-3 py-2 border rounded text-white border-white">
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/></svg>
        </button>

        { sidebarOpen && 
          <div className="bg-slate-800">
            <Menu vertical={true}/>
            <Language />
          </div>
        }
      </div>

      <div className="hidden lg:flex lg:w-2/3">
        <Logo />
        <Menu vertical={false}/>
      </div>
      <div className="hidden lg:flex">
        <Language />
      </div>

    </header>
  )
}