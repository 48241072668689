import Subscription from "../Subscription/Subscription";

export default function ScopeSection() {
  return (
    <section id="scope" className="py-40 px-8 lg:flex bg-slate-800">
        <div className="lg:w-1/2 pr-8 lg:m-auto">
          <h1 className="text-4xl text-white">Hide your email from prying eyes</h1>
          <p className="py-8 text-slate-300 text-justify">
            Improve your email privacy by hiding it from prying eyes using Dami addresses.
          </p>
          <p className="text-slate-300 text-justify">
            Generate new addresses and set where should your emails be forwarded.
            This technique ensures that your real email addresses stay hidden, whether you reply or receive mails.
          </p>
        </div>
        <div id="interest" className="pt-12 lg:w-1/2 lg:pl-8 lg:pt-0">
          <Subscription />
       </div>
    </section>
  )
}