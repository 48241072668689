interface MenuPropsType {
  vertical: boolean;
}
export default function Menu({vertical}: MenuPropsType) {
  return (
    <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
      <div className="text-sm lg:flex-grow">
        <a href="#home" className="block mt-4 lg:inline-block lg:mt-0 text-white mr-4">Home</a>
        <a href="#scope" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mr-4">Scope</a>
        <a href="#features" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mr-4">Features</a>
        <a href="#legal" className="block mt-4 lg:inline-block lg:mt-0 text-white hover:text-white mr-4">Legal</a>
      </div>
    </div>
  )
}